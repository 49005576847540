<template>
  <div>
    <a-spin :spinning="state.is_loading">
      <sdPageHeader :title="$t.helpDesk">
        <template #buttons />
      </sdPageHeader>
      <Main>
        <a-alert
          v-if="state.error_show"
          show-icon
          type="warning"
          message="Uyarı"
        >
          <template #description>
            <span v-html="state.error_message" />
          </template>
        </a-alert>
        <iframe
          v-else
          style="border: 0; width: 100%; height: 700px"
          :src="state.iframe_url"
        />
      </Main>
    </a-spin>
  </div>
</template>

<script>
import { Main } from '../styled'
import { defineComponent, onMounted, reactive } from 'vue'
import restMethods from '@/utility/restMethods'

export default defineComponent({
  name: 'TsoftKargoHelpDesk',
  components: {
    Main,
  },
  setup() {

    const state = reactive({
      is_loading: true,
      error_show: false,
      error_message: '',
      iframe_url: '',
    })

    onMounted(() => {

      state.is_loading = true
      restMethods.postForWebApp('/open-help-desk').then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()
          if (data.type === 'first_usage') {
            state.error_show = true
            state.error_message = data.data.message
          } else if (data.type === 'iframe') {
            state.iframe_url = data.data.iframe_url
          } else {
            state.error_show = true
            state.error_message = 'Bilinmeyen bir hata meydana geldi. Lütfen bize ulaşarak hata hakkında bilgi veriniz.'
          }
        }
        state.is_loading = false
      })

    })

    return {

      state,

    }

  },
})
</script>